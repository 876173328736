import moment from 'moment'
import { prefixer, createApiConsts } from '../../../Helpers/constants'

const createPrefixedConst = prefixer('CISCO_CONFIG')
const createPrefixedApiConsts = <ApiName extends string>(apiName: ApiName) => createApiConsts(createPrefixedConst(apiName))

export const SELECT_ACTION = createPrefixedConst('SELECT_ACTION')
export const SUBMIT_CISCO_CONFIG = createPrefixedApiConsts('SUBMIT_CISCO_CONFIG')
export const GET_CISCO_CONFIG = createPrefixedApiConsts('GET_CISCO_CONFIG')
export const UPDATE_CISCO_CONFIG = createPrefixedApiConsts('UPDATE_CISCO_CONFIG')
export const GET_AUTH_TOKEN = createPrefixedApiConsts('GET_AUTH_TOKEN')
export const GET_TENANT_ACDS = createPrefixedApiConsts('GET_TENANT_ACDS')
export const GET_METADATA = createPrefixedApiConsts('GET_METADATA')
export const VALIDATE_METADATA = createPrefixedApiConsts('VALIDATE_METADATA')
export const GET_SYSTEM_PROPS = createPrefixedApiConsts('GET_SYSTEM_PROPS')

export const ACD_SERVER = 'ACD Server'
export const DB_SERVER_TYPE = 'WEBEX_CALLING'
export const INTERVAL = 10
export const SYNC_INTERVAL_DEFAULT = 10
export const CAPTURE_DELAY_INTERVAL_DEFAULT = 15
export const CWCC2_FORM_DATA = 'cwcc2-formData'
export const RECAPTURE_TIME_FORMAT = 'hh:mm A'
export const DEFAULT_RECAPTURE_TIME = moment().hour(2).minute(0)
// Removing the Client ID on UI (Story #150779)
// export const CISCO_CLIENT_ID = 'C9caffc7deceb77900c89672c815fb3fabcdb88cabb173af9a60ee84a3cb4859f'

// remove / on end of ciscoConfig for localhost testing
export const ADMIN_CISCO_WEBEX_CONFIG_URL = '/admin/webexConfig/'

export const CONSTRAINTS = {
  CAPTURE_DELAY_INTERVAL: {
    MIN: 10,
    MAX: 120,
  },
  SYNC_INTERVAL: {
    MIN: 10,
    MAX: 60,
  },
} as const
